<template>
  <div class="">
    <PreLoader v-if="isLoading" />

    <SponsorForm
      v-if="!isLoading"
      :sponsor="sponsor"
      :imageSrc="imageSrc"
      v-on:addOrUpdateSponsor="addOrUpdateSponsor()"
      :submitName="$t('add')"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "@/utils/create-toast-mixin";
import PreLoader from "@/components/general/PreLoader.vue";
import { STATUS } from "@/utils/constants";
import DEFAULT_IMG from "@/assets/images/sponsors.svg";
// import { numberToEn } from "@/utils/functions";
import SponsorForm from "@/components/sponsors/SponsorForm.vue";
import Sponsor from "@/models/sponsors/Sponsor";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    SponsorForm,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      language: localStorage.getItem("userLanguage") || "ar",
      imageSrc: DEFAULT_IMG,
      sponsor: new Sponsor(),
    };
  },
  methods: {
    async addOrUpdateSponsor() {
      this.isLoading = true;
      try {
        const response = await this.sponsor.addOrUpdateSponsor(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.sponsor.setInitialValue();
          this.showMsg(response.data.msg, true);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
